@tailwind base;
@tailwind components;
@tailwind utilities;

input,
textarea {
    border-top-right-radius: 50px 50px;
    border-bottom-right-radius: 50px 50px;
    border-top-left-radius: 50px 50px;
    border-bottom-left-radius: 50px 50px;
    outline: none;
}

.borderRadius {
    border-top-right-radius: 50px 50px;
    border-bottom-right-radius: 50px 50px;
    border-top-left-radius: 50px 50px;
    border-bottom-left-radius: 50px 50px;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background-color: rgba(255, 255, 255, 0) !important;
}

.active {
    color: #fff;
}

.gradientBackground {
    background-image: linear-gradient(to bottom, #26CDBD, #16756C, #13675F);
}

.animation {
    max-height: 0;
    opacity: 0;
    transition: max-height 0.25s, opacity 0.25s ease-out;
}

.side_bar {

}

/* width */
.side_bar::-webkit-scrollbar {
    width: 5px;
    height: 100px;
}

/* Track */
.side_bar::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
.side_bar::-webkit-scrollbar-thumb {
    background: #FDC11F;
}

/* Handle on hover */
.side_bar::-webkit-scrollbar-thumb:hover {
    background: #555;
}