* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.header {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
}


.loaderBox{
  margin: auto;
  width: 10%;

  padding: 10px;
}
svg {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
 }

 circle {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
 }

 @keyframes rotate4 {
  100% {
   transform: rotate(360deg);
  }
 }

 @keyframes dash4 {
  0% {
   stroke-dasharray: 1, 200;
   stroke-dashoffset: 0;
  }

  50% {
   stroke-dasharray: 90, 200;
   stroke-dashoffset: -35px;
  }

  100% {
   stroke-dashoffset: -125px;
  }
 }
.logoHolder {
  flex-grow: 1;
}
.logoHolder div {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* padding: 1rem; */
  width: 120px;
  height: 120px;
  margin-right: 0.6rem;
}
.logoHolder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.textHeader p:first-child {
  font-size: 36px;
  color: white;
  margin-bottom: 10px;
}
.textHeader span:last-child {
  font-size: 26px;
  color: #ebbc33;
  border: 2px solid #ebbc33;
  border-radius: 42px;
  padding: 3px 10px;
  cursor: pointer;
}

.chevronLeft {
  width: 35px;
  height: 50px;
  position: absolute;
  top: 16px;
  left: 0;
  cursor: pointer;
}

.items {

  border-radius: 1rem;
  padding: 0.2rem 0.5rem;
}
.items p:first-child {

  font-size: 27px;
}
.item {
  display: flex;
  background-color: white;
  /* background: white; */
  border-radius: 20px 20px 20px 50px;
  min-height: 260px;
  /* border: 1px solid #1ebbbf; */
  box-shadow: 0 0 4px 1px #1ebbbf;
  margin-bottom: 1.6rem;
  /* padding: 2px; */
}
.imageHolder {
  /* display: flex; */
  /* align-items: center; */
  /* flex-direction: column; */
  /* background-color: #ffe8f2; */
  width: 200px;
  /* justify-content: center; */
  height: inherit;
}
.imageHolder img {
  width: 200px;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 20px 20px 8px;
}
.imageHolder span {
  color: #0d42a0;
  font-size: 15px;
  font-weight: 700;
}
.contentItem {
  padding: 10px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.itemTitle p:first-child,
.price {
  font-size: 26px;
  font-weight: 500;
  color: #4d4d4d;
}
.itemTitle p:first-child {
  font-weight: 600;
  max-width: 90%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.footerItem {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  align-items: center;
  margin-bottom: 1rem;
  position: relative;
}

.footerItem p:first-child,
.footerItem span {
  color: #4d4d4d;
  font-size: 18px;
}

.button-holder {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.button-holder a{
  justify-content: space-between;
}

.buttonToApp {
  font-size: 22px;
  min-width: 180px;
  min-height: 50px;
  border-radius: 42px;
  background-color: #1ebbbf;
  color: white;
  border: none;
  cursor: pointer;
  margin-right: auto;
  padding: 0 5px;
  text-align: center;
  justify-content: space-between;
}

.contentItemIcons {
   position: absolute;
   top: 1rem;
   left: 1rem;
 }

.contentItemIcons img:first-child {
   margin-bottom: 1rem;
 }

.holeDiv{
  justify-content: "center";
  align-items: "center";
  padding: 2
}
.holes {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  align-items: center;
}
.hole {
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  margin-left: 0.5rem;
  border: 1px solid #1ebbbf;
  border-radius: 50%;
}
img.gift-box {
  width: 1.2rem;
  height: 1.2rem;
}

.item-logo {
  width: 80px;
  height: 60px;
  cursor: pointer;
}
.item-logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.body{
  max-width: 700px;
  margin: auto;
  padding: 2rem 10px;
}

@media (max-width: 500px) {
  .itemTitle p,
  .price{
    flex-direction: row;
    font-size: 15px;
    font-weight: 600;
  }
  .footerItem {
    flex-direction: column;
    margin: 2rem 0;
  }

  .button-holder {
    align-items: center;
  }

  .imageHolder {
    width: 200px;
  }
  .imageHolder img {
    width: 200px;
  }
  .imageHolder span {
    font-size: 10.4px;
  }
  .contentItemIcons img {
    width: 24px;
    height: 24px;
  }
  .contentItemIcons {
    top: 1.6rem;
  }
}
@media (max-width: 600px) {
  .itemTitle p:first-child,
  .price {
    flex-direction: row;
    font-size: 20px;
    font-weight: 600;
  }
  .itemTitle p:first-child {
    /*padding-left: 1.8rem;*/
  }
  button {
    width: 100px;
    height: 50px;
    font-size: 24px;
  }
  .footerItem{
    flex-direction: column;
  }
  .footerItem p:first-child,
  .footerItem span {
    color: #4d4d4d;
    font-size: 18px;
  }


}

