.card_container{
    display:flex;
    flex-direction: column;
    align-content: center;
    padding: 20px;
    margin: auto;
}
.card {
    background-color: #26CDBD !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    overflow: hidden;
    padding: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    margin-top: 1rem;
    margin-bottom: 1rem;

}

.card_container h3{
    color: white;
    font-size: 28px;
    font-weight: 600;

}
.card_container span{
    color: white;
    font-size: 38px;
}
.detail-section{
    padding:15px;
    text-align: left;
}

.picture {
    width: 100%;
    height: 200px;
    flex-direction: column;
    object-fit: cover;
}


.card_container p{
    color: white;
    font-size: 16px;
    font-weight: 600;
}
.card_container span{
    color: unset;
    font-size: 16px;
    font-weight: 400;
}


@media (min-width:768px) {

    .card_container{
        width: 60vw;
    }
    .card{
        flex-direction: row;
        height: 250px;
        width: 100%;
    }

    .picture{
        height: 100%;
    }

    .detail-section{
        text-align: left;
        padding: 20px;
    }

}