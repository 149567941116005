.linearBG{
    background-image: linear-gradient(to bottom, #26CDBD,#16756C,#13675F);
    position: relative;
    overflow: hidden;
}


.helpDiv {
    position: relative;
    display: inline-block;
    /* margin-top: 20px; */
}

.helpDiv[title]:hover::before{
    color: red;

}

.card_container_club {
    background-color: #26CDBD !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   
    display: flex;
    flex-direction: column;
 
    border-radius: 15px;
    padding: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .card_container_club h3{
    color: white;
    font-size: 28px;
    font-weight: 600;
    
  }
  .card_container_club span{
    color: white;
    font-size: 38px;
    
    
  }
.detail-section{
 
  width: 100%;
}
  .card_container_club p{
    color: white;
    font-size: 16px;
    font-weight: 600;
  }
  .card_container_club span{
    color: unset;
    font-size: 16px;
    font-weight: 400;
  }

.helpDiv[data-title]:hover:after {
    text-align: center;
    min-width: 130px;
    background-color: #FDC11F;
    border-radius: 10px;
    color: white !important;
    font-size: 14px;
    text-transform: uppercase;
    padding: 2px 15px;
    content: attr(data-title);
    position: absolute;
    left: 100%;
    word-wrap: normal;
    border: 1px solid #121212;
    z-index: 10000;
}