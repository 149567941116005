/* Navbar.css */

.navbar {
    background-color: #333;
    color: white;
    padding: 10px 20px;
    position: relative;
    z-index: 1000;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-size: 2rem;
    font-weight: bold;
}

.nav-links {
    display: flex;
    gap: 20px;
}

.nav-link {
    color: #fff;
    font-size: 2rem;
    text-decoration: none;
    padding: 10px 15px;
    transition: background-color 0.3s;
}

.nav-link:hover,
.active {
    background-color: #555;
    border-radius: 5px;
}

.menu-icon {
    display: none;
    font-size: 2rem;
    cursor: pointer;
}

.drawer {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 250px;
    height: 100vh;
    background-color: #333;
    flex-direction: column;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
}

.drawer.open {
    display: flex;
    transform: translateX(0);
}

.drawer-link {
    color: #fff;
    text-decoration: none;
    padding: 15px 0;
    transition: color 0.3s;
}

.drawer-link:hover {
    color: #ddd;
}

.logout{
    color: #f00;
    font-size: 2rem;
    cursor: pointer;
    text-decoration: none;
    padding: 10px 15px;
    transition: background-color 0.3s;
}

.logout:hover {
    color: #ff5555;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .logo{
        font-size: 1.5rem;
    }
    .nav-links {
        display: none;
    }

    .menu-icon {
        display: block;
    }

}
