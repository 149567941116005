.linearBG{
    background-image: linear-gradient(to bottom, #26CDBD,#16756C,#13675F);
    position: relative;
    overflow: hidden;
}

.react-tel-input .form-control {
    width: 90% !important;
}

@media screen and (max-width: 576px) {
    .react-tel-input .form-control {
        width: 90% !important;
    }
}